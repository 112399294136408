<template>
  <div class="container-sm">
    <br><br><br><br><br>
    <router-link
      :to="{ name: 'home' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0">
          บัญชีของฉัน
        </h5>
      </div>
    </router-link>

    <div
      class="mt-1 card"
      style="padding: 5px 10px"
    >
      <div class="d-flex align-items-center">
        <i class="icon fad fa-user-circle fa-3x mr-1" />
        <div>
          <h5
            class="font-weight-bolder m-0"
            style="color: #000 !important"
          >
            {{ User.name }} {{ User.surname }}
          </h5>
          <small class="font-weight-normal">
            {{ User.tel }}
          </small>
        </div>
      </div>
    </div>

    <div class="ac-top p-0">
      <div class="row">
        <div class="col-12">
          <div class="card bg-balance p-1">
            <small
              class="text-white"
            ><i class="fas fa-wallet" /> เครดิตคงเหลือ</small>
            <h3 class="text-white">
              ฿ {{ (balance).toFixed(2) }}
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-tabs">
      <div class="d-flex align-items-center">
        <div
          class="w-50 text-center p-50 tab-acc cursor-pointer"
          :class="{'active': isActive === 1}"
          @click="isActive = 1"
        >
          บัญชีธนาคาร
        </div>

        <div
          class="w-50 text-center p-50 tab-acc cursor-pointer"
          :class="{'active': isActive === 2}"
          @click="isActive = 2"
        >
          ทรูมันนี่ วอลเล็ท
        </div>
      </div>

      <div
        v-if="isActive ===1"
        class="p-50"
      >
        <div class="card-bank mt-1 p-1">
          <div class="d-flex align-items-center">
            <div
              class="box-banks"
              :style="{ backgroundColor: User.bg }"
            >
              <img
                :src="`/bankIcon/${User.path_photo}`"
                alt="bank"
                height="25"
              >
            </div>
            <div>
              <h5
                class="m-0 font-weight-bolder"
                style="color: #000"
              >
                {{ User.bankname }} จำกัด (มหาชน)
              </h5>
            </div>
          </div>

          <div class="mt-1 mb-1 d-flex justify-content-between">
            <img
              src="/chip.png"
              alt="chip"
              height="40"
            >
          </div>

          <div>
            <div class="box-bank">
              <h4 class="m-0 dark font-weight-bolder">
                {{ User.acc_no }}
              </h4>
            </div>
            <div>
              <h5 class="m-0 dark">
                {{ User.name }} {{ User.surname }}
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isActive === 2"
        class="p-50"
      >
        <div class="card-bank mt-1 p-1">
          <div class="d-flex align-items-center">
            <div
              style="
                  background-color: #fff;
                  border-radius: 6px;
                  width: 35px;
                  height: 35px;
                  padding: 5px;
                  margin-right: 5px;
                "
            >
              <img
                src="/bankIcon/true.svg"
                alt="bank"
                height="25"
              >
            </div>
            <div>
              <h5
                class="m-0 font-weight-bolder"
                style="color: #000"
              >
                ทรูมันนี่ วอลเล็ท
              </h5>
            </div>
          </div>

          <div class="mt-1 mb-1 d-flex justify-content-between">
            <img
              src="/chip.png"
              alt="chip"
              height="40"
            >
          </div>

          <div>
            <div class="box-bank">
              <h4 class="m-0 dark font-weight-bolder">
                <!-- {{ agent.dip_bank_accno }} -->
                {{ User.trueaccno }}
              </h4>
            </div>
            <div>
              <h5 class="m-0 dark">
                <!-- {{ agent.dip_bank_accname }} -->
                {{ User.name }} {{ User.surname }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pb-150">
      <button
        class="btn mb-2 btn-block router-link-active btn-theme-two"
        @click="logout()"
      >
        <i class="fas fa-sign-out" /> ออกจากระบบ
      </button>
    </div>
  </div>
</template>

<script>
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
  },
  props: {
    // eslint-disable-next-line
    UserProfile: Object,
  },
  data() {
    return {
      isActive: 1,
      balance: 0.0,
      amount: '',
      UserData: JSON.parse(localStorage.getItem('userData')),
      User: {},
      wto: null,
    }
  },
  mounted() {
    this.Getbalance()
    this.GetUser(this.UserData.userID)
  },
  methods: {
    GetUser(userID) {
      this.$http
        .get(`/users/show/${userID}`)
        .then(response => {
          this.User = response.data
          // console.log(this.User)
        })
        .catch(error => console.log(error))
    },
    Getbalance() {
      this.$http
        .get('/balance/getbalance')
        .then(response => {
          // console.log(response.data)
          this.balance = response.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
        })
        .catch(error => console.log(error))
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style scoped>
.card {
  background: #fff !important;
  color: #000 !important;
}
.bg-balance {
  background: linear-gradient(180deg, #414141, #000000) !important;
  background-repeat: repeat !important;
  background-size: 100% auto !important;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #fff !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #fff;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 4px;
  position: relative;
}
.btn-orange {
  background: linear-gradient(180deg, #5a499d, #bd6aaa) !important;
  width: 100%;
  border: none;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.box-ac-items {
  padding: 10px;
  border-radius: 5px;
  border: #000 1px solid;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.card-bank {
  min-height: 175px;
  border-radius: 14px;
  /* background-image: url('/bg-bank2.svg'); */
  background: linear-gradient(
      0deg,
      rgba(185, 153, 91, 0.9),
      rgba(221, 204, 151, 0.9),
      rgba(185, 153, 91, 0.9)
    ),
    url("/bg-bank2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
  padding: 10px;
}
.dark {
  color: #000;
}
.box-banks {
  border-radius: 6px;
  width: 35px;
  height: 35px;
  padding: 5px;
  margin-right: 5px;
}
.pb-150 {
  padding-bottom: 150px;
}
.bg-tabs {
  background: #232323;
  border-radius: 10px !important;
  margin-bottom: 20px;
}

.tab-acc:first-child {
  background: #232323;
  border-bottom: 2px solid #ffa021;
  border-radius: 10px 10px 0 0;
}

.tab-acc:last-child {
  background: #232323;
  border-bottom: 2px solid #ffa021;
  border-radius: 10px 10px 0 0;
}
.tab-acc.active {
  background: linear-gradient(180deg, #ffe872 25%, #ffa021);
  color: #000000;
  /* border-radius: 10px; */
}
</style>
